.App-container {
  max-width: 1380px; /* Set maximum width */
  margin: 0 auto; /* Center align the container */
  padding: 20px; /* Optional: Adds padding inside the container */
  width: 100%; /* Ensure it takes full width up to the max-width */
}

.table2 {
  font-size: 12px;
}

.currency {
  font-size: 12px;
  color: #888888;
}

.wideCol {
  width: 100px;
}


.redC {
 color: #dc3545!important;
}

.greenC {
color: #198754!important;
}

.grayCell > * {
  background: #c4c4c4 !important;
  color: #5e5e5e !important;
}

.activeCell {
  border: 2px solid lightseagreen!important;
}

.row-cell:hover {
  cursor: pointer!important;
}
